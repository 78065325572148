import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ContentFeaturedLinksImageLinkCards from '../components/ContentFeaturedLinksImageLinkCards';
import ModularBlocks from '../components/ModularBlocks';

const HomePageTemplate = ({
  data: { datoCmsHome },
  pageContext: { locale },
}) => {
  const {
    seoMetaTags,
    slugLocales,
    title,
    banner,
    imageLinkCards,
    contentHeading,
    contentText,
    contentLink,
    featuredLinksHeading,
    featuredLinks,
    modularBlocks,
  } = datoCmsHome;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsHome}
    >
      <main>
        <Banner
          heading={title}
          text={banner.text}
          link={banner.link}
          image={banner.image}
          isHomePage={true}
        />
        <ContentFeaturedLinksImageLinkCards
          heading={contentHeading}
          text={contentText}
          link={contentLink}
          featuredLinksHeading={featuredLinksHeading}
          featuredLinks={featuredLinks}
          imageLinkCards={imageLinkCards}
          isHomepage={true}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const HomePageTemplateQuery = graphql`
  query HomePageTemplateQuery($id: String!) {
    datoCmsHome(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      banner {
        ...BannerFragment
      }
      imageLinkCards {
        ...ImageLinkCardsItemFragment
      }
      contentHeading
      contentText
      contentLink {
        text
        page {
          ...LinkFragment
        }
      }
      featuredLinksHeading
      featuredLinks {
        text
        page {
          ...LinkFragment
        }
      }
      modularBlocks {
        ...ContentModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedResourcesModularBlockFragment
        ...FeaturedTestimonialsModularBlockFragment
        ...ImageContentModularBlockFragment
        ...TabsModularBlockFragment
      }
      ...LinkFragment
    }
  }
`;

export default HomePageTemplate;
